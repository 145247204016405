<template>
  <main>
    <slot />
    <error-dialog />
    <snackbar/>
  </main>
  <div
      v-if="ucActive"
      class="hover:opacity-100 bg-white opacity-50 bottom-6 w-max md:left-8 right-4 z-30 fixed py-2 px-3 rounded-3xl flex flex-row cursor-pointer"
      @click="openPrivacyOptions"
    >
      <svg
        class="mr-1"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.64526 17.3125C9.64526 17.3125 15.6453 14.3125 15.6453 9.8125V4.5625L9.64526 2.3125L3.64526 4.5625V9.8125C3.64526 14.3125 9.64526 17.3125 9.64526 17.3125Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="text-button font-button">{{ $t('global.cookies') }}</div>
    </div>
</template>

<script lang="ts">
export default {
  data () {
    const { t } = useI18n();
    const ucActive = ref(false);
    const maxRetires = ref(5);
    const interval = setInterval(this.checkUserCentricsInitialized, 1000)
    return {
      t, ucActive, maxRetires, interval
    }
  },
  methods: {
    checkUserCentricsInitialized () {
      if (this.maxRetires !== 0) {
      this.maxRetires = this.maxRetires - 1
      if (process.client) {
       this.ucActive = window?.UC_UI?.isInitialized() ?? false
      }
       if (this.ucActive) {
          clearInterval(this.interval)
       }
      } else {
        clearInterval(this.interval)
      }
    },
    openPrivacyOptions () {
      if (process.client) {
        window?.UC_UI?.showSecondLayer();
      }
    }
  }

}
</script>

<style>
html {
  @apply bg-anybill-canvas
}
</style>