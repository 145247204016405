<template>
  <anybill-dialog v-model="showErrorDialog">
    <template #title>{{ $t(errTitle) }}</template>
    <template #content>
      <span v-html="$t(errContent)" />
    </template>
  </anybill-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { DialogTypes, useDialogsStore } from "~/stores/dialogs";

const dialogStore = useDialogsStore();

const { type, args } = storeToRefs(dialogStore);

const showErrorDialog = computed(() => type.value === DialogTypes.ERR);
const errContent = computed(() => args.value?.errContent ?? "");
const errTitle = computed(() => args.value?.errTitle ?? "");
</script>
