<template>
  <div
    v-show="isOpen"
    id="snackbar-wrapper"
    class="fixed bottom-8 flex justify-center w-screen"
  >
    <div
      class="flex justify-between items-center bg-[#323232] max-w-max rounded-md py-2 px-6"
      :style="{'background-color': snackColor}"
    >
      <span
        class="text-white mr-2 select-none max-w-[250px] md:max-w-[300px] lg:max-w-[450px] pr-16"
        v-html="$t(snackText, snackTextArgs)"
      />

      <span
        id="close-span"
        class="text-white text-xs select-none cursor-pointer"
        @click="close"
        >{{ $t("snackbar.close") }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { DialogTypes, useDialogsStore } from "~/stores/dialogs";
import { computed, watch } from "vue";

const dialogStore = useDialogsStore();
const { type, args, color } = storeToRefs(dialogStore);

const snackText = computed(() => args.value?.textId ?? "");
const snackColor = computed(() => color.value ?? "");
const snackTextArgs = computed(() => args.value?.textArgs ?? {});
const persistent = computed(() => args.value?.persistent ?? false);
const isOpen = computed(() => type.value === DialogTypes.SNACKBAR);

watch(isOpen, (change) => {
  if (change && !persistent.value) {
    setTimeout(() => {
      close();
    }, 5000);
  }
});

function close() {
  dialogStore.close();
}
</script>

<style scoped></style>
